define(['siteObj'], function (siteObj) {
  const MS_IN_A_DAY = 24 * 60 * 60 * 1000;
  const removeCloak = el => el.classList.remove('cloak');
  const hideComponent = el => el.classList.add('productReleaseCountDown_hide');
  const DECREMENT = 1000;

  const $interval = (fn, ms) => {
    const id = setInterval(fn, ms);
    return () => clearInterval(id);
  };

  const productReleaseCountdown = () => {
    const component = {};

    const _config = {
      props: {
        endDay: {
          selector: 'data-count-down-day',
          defaultValue: '1' //past date as default to ensure the timer will hide w/o inputs
        },
        endMonth: {
          selector: 'data-count-down-month',
          defaultValue: 'January'
        },
        endYear: {
          selector: 'data-count-down-year',
          defaultValue: '2020'
        },
        daysVisible: {
          selector: 'data-count-down-days-visible',
          defaultValue: '14'
        }
      },
      children: {
        weeks: '.productReleaseCountDown_weeks',
        days: '.productReleaseCountDown_days',
        hrs: '.productReleaseCountDown_hrs',
        mins: '.productReleaseCountDown_mins',
        secs: '.productReleaseCountDown_secs',
        timer: '.productReleaseCountDown_countdown',
      },
      computed: {
        get remainingWeeks() {
          return component.computed.remainingDays / 7;
        },
        get remainingDays() {
          return component.computed.remainingHrs / 24;
        },
        get remainingHrs() {
          return component.computed.remainingMins / 60;
        },
        get remainingMins() {
          return component.computed.remainingSecs / 60;
        },
        get remainingSecs() {
          return component.computed.remainingMs / 1000;
        },
        get remainingMs() {
          return component.getReleaseMillis(component.props.endDay, component.props.endMonth, component.props.endYear)  -  Date.now();
        },
        get remainingRoundedWeeks() {
          return Math.floor(component.computed.remainingWeeks);
        },
        get remainingRoundedDays() {
          return Math.floor(component.computed.remainingDays % 7);
        },
        get remainingRoundedHrs() {
          return Math.floor(component.computed.remainingHrs % 24);
        },
        get remainingRoundedMins() {
          return Math.floor(component.computed.remainingMins % 60);
        },
        get remainingRoundedSecs() {
          return Math.floor(component.computed.remainingSecs % 60);
        },
        get isCountdownOver() {
          return component.computed.remainingMs <= 0;
        },
        get shouldCountdownStart() {
          return component.computed.remainingMs <= MS_IN_A_DAY * parseInt(component.props.daysVisible);
        },
      },
    };

    const _init = (element) => {
      component.element = element;
      component.children = {};
      for (let [childrenName, childrenSelector] of Object.entries(_config.children)) {
        component.children[childrenName] = element.querySelector(childrenSelector);
      }

      component.props = {};
      for (let [propName, {selector, defaultValue, transform = x => x}] of Object.entries(_config.props)) {
        component.props[propName] = transform(element.getAttribute(selector) || defaultValue);
      }

      if(component.computed.shouldCountdownStart) {
        component.stopTicking = $interval(component.tick, DECREMENT);
        component.tick();
        removeCloak(component.children.timer);
      } else {
        hideComponent(element);
      }

      return component;
    };

    const _tick = () => {
      if(component.computed.isCountdownOver) {
        component.element.classList.add('productReleaseCountDown_hide');
        component.stopTicking();
        return;
      }

      component.children.weeks.innerHTML = component.prependZero(component.computed.remainingRoundedWeeks);
      component.children.days.innerHTML  = component.prependZero(component.computed.remainingRoundedDays);
      component.children.hrs.innerHTML   = component.prependZero(component.computed.remainingRoundedHrs);
      component.children.mins.innerHTML  = component.prependZero(component.computed.remainingRoundedMins);
    };

    const _prependZero = (int) => {
      return (int < 10) ? '0' + int : int;
    };

    const _getReleaseMillis = (day, month, year) => {
      return (new Date(day + ' ' + month + ' ' + year)).getTime();
    };

    component.init = _init;
    component.tick = _tick;
    component.computed = _config.computed;
    component.getReleaseMillis = _getReleaseMillis;
    component.prependZero = _prependZero;

    return component;
  };

  return productReleaseCountdown;
});
